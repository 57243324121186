import React from "react";
import FAQ from "./components/FAQ";
import PricingPro from "./components/PricingPro";
import ScrollProvider from "components/ScrollProvider";
import { Helmet } from "react-helmet-async";
import PricingBasic from "./components/PricingBasic";
import Button from "components/Button";
import { IconCamera, IconPro } from "icons";
import clsx from "clsx";
import { useLocation } from "react-router-dom";

export enum PricingType {
  Design = "Design",
  Photoshoot = "Photoshoot",
}

const Pricing = () => {
  const _location = useLocation();
  const { type } = _location.state || {};
  const [currentPricing, setCurrentPricing] = React.useState(type ?? PricingType.Design);

  React.useEffect(() => {
    if (type) {
      setCurrentPricing(type);
    }
  }, [type]);

  return (
    <ScrollProvider>
      <div className="container-fluid">
        <Helmet>
          <title>Pricing</title>
          <meta name="description" content="Give Refabric a try and see the power of AI generated design for yourself." />
          <meta name="twitter:title" content="Pricing" />
          <meta name="twitter:description" content="Give Refabric a try and see the power of AI generated design for yourself." />
          <meta property="og:title" content="Pricing" />
          <meta property="og:description" content="Give Refabric a try and see the power of AI generated design for yourself." />
          <link rel="canonical" href={location.href} />
        </Helmet>

        <div className="flex flex-col w-full gap-10 lg:gap-[60px] pt-10 lg:pt-[75px] pb-20 lg:pb-28">
          <div className="flex flex-col gap-8 lg:gap-12 px-8 lg:px-[60px]">
            <h2 className="text-h3-satoshi">Pricing</h2>

            {/* <div className="flex gap-4">
              <Button
                className={clsx("text-h4 gap-5 !p-4 rounded-[10px] hover:bg-[#F5F4F3] border-[4px]", currentPricing === PricingType.Design ? "border-green bg-[#F5F4F3]" : "border-white")}
                onClick={() => setCurrentPricing(PricingType.Design)}
              >
                <IconPro className="w-8 h-8" />
                Design
              </Button>

              <Button
                className={clsx("text-h4 gap-5 !p-4 rounded-[10px] hover:bg-[#F5F4F3] border-[4px]", currentPricing === PricingType.Photoshoot ? "border-green bg-[#F5F4F3]" : "border-white")}
                onClick={() => setCurrentPricing(PricingType.Photoshoot)}
              >
                <IconCamera className="w-8 h-8" />
                Photoshoot
              </Button>
            </div>

            <div className="flex flex-col">
              <span className="text-bodyLg max-w-[720px]">Give Refabric a try and see the power of AI generated design for yourself.</span>
            </div> */}
          </div>

          <PricingPro />
          {/* {currentPricing === PricingType.Design ? <PricingPro /> : <PricingBasic />} */}

          <div className="flex justify-center mt-0 lg:mt-[60px] px-[15px] lg:px-0">
            <FAQ />
          </div>
        </div>
      </div>
    </ScrollProvider>
  );
};

export default Pricing;
